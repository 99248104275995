import { useEffect, useMemo } from 'react';
import { useMsal } from '@azure/msal-react';
import { useFormContext } from "react-hook-form";
import { useLocation } from 'react-router-dom';

import { CLASnackbarWithContent } from "@ais/components";
import {
    INTERNAL_CONTROLS_FORM_TYPE_ID
} from '@ais/constants';
import { transformAnswerFromUpdate } from '@ais/utilities';
import { useRoomIdle } from '@ais/hooks';

import { useOthers, useOtherUsersStore } from '@providers/concurrency/store/users';
import { useICF } from '@providers/concurrency/hooks/useICF';
import { useEventListener, useOthersListener, useUpdateMyPresence } from '@providers/concurrency/RoomProvider';
import { useProjectFormContext } from '@contexts';
import { useAppTypeProvider } from '@ais/providers';
import logger from '@utilities/logService'

export function ConcurrentUsersBanner() {
    const { accounts } = useMsal()
    const userId = accounts[0].localAccountId.toLowerCase();
    const append = useOtherUsersStore((state) => state.append)
    const remove = useOtherUsersStore((state) => state.remove)
    const updateUserPresence = useOtherUsersStore((state) => state.updateUserPresence)
    const isIdle = useRoomIdle();
    const updateMyPresence = useUpdateMyPresence();
    const { setValue } = useFormContext();
    const {
        projectForm: { formTypeId }, answers, focusedId, fieldRef, actions: { onFocus, onUpdateEvent }
    } = useProjectFormContext();
    const { isPortalApp } = useAppTypeProvider();
    const {
        setInternalControlData,
        setConcurrencyEventReceived
    } = useICF();
    const { pathname } = useLocation(); //TEMPORARY. THIS SHOULD BE REMOVE ONCE V2 IS TESTED ALREADY!!!
    const isV2 = useMemo(() => {
        return pathname.includes("project/") && !pathname.includes("v1/project/");
    }, [pathname]);

    const handleUpdateEvents = (data) => {
        const { answer, id } = data;
        switch (formTypeId) {
            case INTERNAL_CONTROLS_FORM_TYPE_ID: 
                setConcurrencyEventReceived(true);
                setInternalControlData(answer)
                return;
            default:
                const updatedAnswer = isV2 ? transformAnswerFromUpdate(id, answer.answerValue) : answer.answerValue;
                setValue(id, updatedAnswer)
                onUpdateEvent(prev => {
                    return { ...prev, [id]: updatedAnswer }
                })
                return;
        }
    }

    useOthersListener(({ type, sessionId, data }) => {
        switch (type) {
            case "enter":
                append({ sessionId, ...data })
                return
            case "leave":
                remove(sessionId)
                return
            case "presence":
                updateUserPresence(sessionId, data)
            default:
                logger.warn(`Invalid event type: `, type)
        }
    })

    useEventListener(({ type, data }) => {
        switch (type) {
            case "updates":
                if (data.answer?.userId?.toUpperCase() !== userId?.toUpperCase()) {
                    handleUpdateEvents(data);
                }
                return
            default:
                logger.warn(`Invalid event type: `, type)
        }
    })

    useEffect(() => {
        if (isIdle) {
            if (focusedId) {
                setValue(focusedId, answers[focusedId])
                fieldRef.current?.blur()
                fieldRef.current = null
                onFocus(null, null)
            }
            updateMyPresence({ focusedId: null })
        }
    }, [isIdle])

    const getDisplayUsers = (displayUsers, user) => {
        if (user.info.userId?.toLowerCase() !== userId?.toLowerCase() && !displayUsers.some(function (displayUser) { return displayUser.info.name === user.info.name; })) {
            displayUsers.push(user);
        }
        return displayUsers;
    }

    const users = useOthers()
    return (
        <CLASnackbarWithContent
            isExt={!isPortalApp}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            snackBarPostion={'top-center'}
            open={users?.reduce(getDisplayUsers, []).length > 0}
            message={
                <>
                    {users?.reduce(getDisplayUsers, []).map((item, index) => (
                        <p key={index} style={{ textAlign: 'center', margin: 0 }}>
                            {`${item.info.name} is currently editing this form`}
                        </p>
                    ))}
                </>
            }
        />
    )
}

