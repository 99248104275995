import { createContext, useContext, useMemo, useState, useRef, useEffect } from "react"
import { useParams } from "react-router-dom";
import { json, useLocation } from 'react-router-dom';
import { useQuestionsWithLink, useSubmitFormMutation } from "@services";
import { QUESTION_LINK_TYPE } from "@ais/constants"
import { useFormContext } from "react-hook-form"; 
import { CustomToast } from '@ais/components';
import { useFormAnswersMutation } from "@services";
import { useUpdateMyPresence } from '@providers/concurrency/RoomProvider';

const ProjectFormContext = createContext()

export const ProjectFormContextProvider = ({ projectForm, answers = {}, children }) => { 
    const { projectId, projectFormId } = useParams();

    const { pathname } = useLocation();
    const isV2 = useMemo(() => {
        return pathname.includes("project/") && !pathname.includes("v1/project/");
    }, [pathname]);
    
    const { reset } = useFormContext();
    const { data: linkedQuestions } = useQuestionsWithLink(projectId, projectFormId);
    const { mutateAsync: submitFormMutate } = useSubmitFormMutation(projectFormId, projectId); 
    const { mutateAsync: submitAnswers, error, isLoading, isError, isSuccess } = useFormAnswersMutation(projectId, projectFormId, isV2) 
    const [isQuestionGroupFocused, setIsQuestionGroupFocused] = useState(false);
    const [isCustomFormError, setIsCustomFormError] = useState(false);
    const [isCustomFormSuccess, setIsCustomFormSuccess] = useState(false);
    const [isCustomFormLoading, setIsCustomFormLoading] = useState(false); 
    const [isQuestionGroupIdle, setIsQuestionGroupIdle] = useState(false);
    const [focusedId, setFocusedId] = useState(null)
    const [answerList, setAnswerList] = useState(answers)
    const fieldRef = useRef(null); 

    const linkDefaultList = useMemo(() => {
        if(!projectForm) {
            return []
        }
        const list = projectForm.schema.flatMap(section => section)?.flatMap(row => row.fields)?.flatMap(field => field)?.flatMap(field => (
            {
              id: field.id,
              isUserModified: !!field.isUserModified,
              existingQuestionId: field.existingQuestionId,
              linkType: field.linkType
            }
        ))?.filter(field => field.linkType === QUESTION_LINK_TYPE.QUESTION_LINK_TYPES[2])
        return list
    }, [projectForm]);

    const submitForm = async () => {
        await submitFormMutate();
    }

    const handleUpdateIsQuestionGroupFocused = (isQuestionGroupFocused) => {
        setIsQuestionGroupFocused(isQuestionGroupFocused);
      };

    const handleFocusField = (e, id) => {
        if (e) fieldRef.current = e.target;
        setFocusedId(id);
    }

    const handleOnBlur = () => {
        setFocusedId(null)
    }

    const handleSubmitAnswers = async (payload) => { 
        await submitAnswers(payload);
    }

    const handleCustomFormSavingState = (type, value) => {
        if (type === 'isLoading') setIsCustomFormLoading(value);
        if (type === 'isSuccess') setIsCustomFormSuccess(value);
        if (type === 'isError') setIsCustomFormError(value);
    };

    useEffect(() => { 
        if (Object.keys(answerList).length > 0) {
            reset(answerList);
        }
    }, [answerList])
    
    useEffect(() => {
        if (Object.keys(answers).length > 0) {
            setAnswerList(answers)
        } 
    }, [answers])

    useEffect(() => {
        if (!error || !error.response) return;
        const { data, status } = error.response;

        throw json(data, status);
    }, [error])

    return (
        <ProjectFormContext.Provider value={{
            projectId,
            projectFormId,
            projectForm,
            answers,
            linkedQuestions,
            linkDefaultList,
            isQuestionGroupFocused,
            focusedId,
            fieldRef,
            isQuestionGroupIdle,
            actions: {
                submitForm,
                updateIsQuestionGroupFocused: handleUpdateIsQuestionGroupFocused,
                onCustomFormSaving: handleCustomFormSavingState,
                updateQuestionGroupIdle: setIsQuestionGroupIdle,
                onFocus: handleFocusField,
                onBlur: handleOnBlur,
                onUpdateEvent: setAnswerList,
                submitAnswers: handleSubmitAnswers
            }
        }}>
            { children }  
            <CustomToast
                error={isError || isCustomFormError}
                success={isSuccess || isCustomFormSuccess}
                loading={isLoading || isCustomFormLoading}
            />
        </ProjectFormContext.Provider>
    )
}

export const useProjectFormContext = () => {
    const ctx = useContext(ProjectFormContext)
    if(!ctx) {
        throw new Error("`useProjectFormContext` must be used within ProjectFormContextProvider")
    }
    return ctx
}
