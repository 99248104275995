import { useMemo } from 'react';
import { isEqual } from "lodash";
import { useLocation } from 'react-router-dom';
import { useFormContext } from "react-hook-form";
import { Box } from '@mui/material';

import { isAnswerQuestionGroup, isAnswerTable, transformAnswersToPayload } from '@ais/utilities';

import { FormSection } from "@components/ProjectForm";
import { ConcurrentUsersBanner } from "@components/Concurrency";
import { useProjectFormContext } from "@contexts";
import logger from '@utilities/logService';
import { getQuestionId } from '@utilities/helpers';

export const ProjectForm = ({ schema, answers }) => {
    const { projectId, projectForm, actions: { submitAnswers, onUpdateEvent} } = useProjectFormContext(); 
    const { handleSubmit: formMethodsHandleSubmit } = useFormContext();
    const { pathname } = useLocation(); //TEMPORARY. THIS SHOULD BE REMOVE ONCE V2 IS TESTED ALREADY!!! 
    const isV2 = useMemo(() => {
        return pathname.includes("project/") && !pathname.includes("v1/project/");
    }, [pathname]);

    const getIdsWithUpdatedValues = (obj, source) =>
    Object.keys(source).filter(
        (key) =>
            !obj.hasOwnProperty(key) ||
            (obj.hasOwnProperty(key) && !isEqual(obj[key], source[key]))
    )

    const handleSubmitV2 = async (questionId, currentAnswers, answerList) => {
        const isQuestionGroup = isAnswerQuestionGroup(questionId);
        questionId = getQuestionId(questionId);
        const answer = currentAnswers[questionId];
        const isTable = isAnswerTable(answer);
        if (answer === answerList[questionId]) return;

        const transformedValue = transformAnswersToPayload(answer, isTable, isQuestionGroup);
        
        if(isQuestionGroup || isTable)
            if(JSON.stringify(answer.map(ans => {
                const obj = {}
                for(const key in ans){
                    if(ans[key] !== null && ans[key] !== undefined) obj[key] = ans[key]
                }
                return obj
            })) === JSON.stringify(answerList[questionId])) return
        try {
            const payload = {
                projectId,
                projectUnitIds: projectForm.units.map((unit) => unit.ProjectUnitID),
                questionId,
                answer: transformedValue
            }; 
            
            await submitAnswers(payload);
            onUpdateEvent((prevAnswers) => {
                const answers = prevAnswers;
                for(const key of Object.keys(prevAnswers)){
                    if(key === questionId) answers[key] = transformedValue;
                }
                return answers;
            })
        } catch (error) {
            logger.error(error.response.data.message ?? error.message);
            //Toast handles seperately via property state awareness of mutation Object
        }
    }

    const handleSubmit = async (formValues, id) => {
        if (isV2) {
            handleSubmitV2(id, formValues, answers);
            return;
        }
        const updatedValues = getIdsWithUpdatedValues(answers, formValues)
        for (const updatedValue of updatedValues) {
            const updatedAnswer = formValues[updatedValue]
            if (updatedAnswer === null || updatedAnswer === undefined) {
                return
            }
            const payload = {
                projectId,
                projectUnitIds: projectForm.units.map((unit) => unit.ProjectUnitID),
                questionId: updatedValue,
                answer: updatedAnswer
            }
            await submitAnswers(payload)
        } 
    }

    return (
        <Box style={{ width: "100%" }}>
            <FormSection handleSubmit={formMethodsHandleSubmit(handleSubmit)} schema={schema} />
            {isV2 && <ConcurrentUsersBanner />}
        </Box>
    )
}

export default ProjectForm
